import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { Link, useMatch, useLocation } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { LayoutContext } from "../../routing/LayoutContext";
import ContactButton from "../ui/ContactBtn";
import houseIcon from "../../img/icons/houseWhite.png";
const exeptionsOfTransparentEffect = ["document", "cv"];

const useStaticDesign = ["document", "cv"];

const dontShow = ["private", "geogame2"];

const NavbarLinkStandard = ({ label, to }) => {
  let match = useMatch(to);
  return (
    <Fragment>
      <Link
        to={to}
        className={match ? "navbar-link navbar-link-acive" : "navbar-link"}
        aria-label={label}
      >
        {label}
        <div
          className={
            match
              ? "navbar-link-underline navbar-link-underline-acive"
              : "navbar-link-underline"
          }
        />
      </Link>
    </Fragment>
  );
};
const NavbarLinkLanding = ({ label, to, style, show }) => {
  let match = useMatch(to);
  return (
    <Link
      to={to}
      className={`${match && "navbar-link-acive"} navbar-link`}
      style={style}
      aria-label={label}
    >
      <Fade top collapse when={show}>
        {label}
      </Fade>
    </Link>
  );
};

const NavbarLinkMobile = ({ label, img, imgClass, to, style, onClick }) => {
  let match = useMatch(to);
  return (
    <Fragment>
      <Link
        to={to}
        className="navbar-mobile-link"
        style={style}
        onClick={onClick}
        aria-label={label}
      >
        <font
          className={`${
            match && !img && "navbar-mobile-link-font-acive"
          } navbar-mobile-link-font`}
        >
          {img ? <img src={img} className={imgClass} /> : label}
        </font>
      </Link>
    </Fragment>
  );
};
const Navbar = () => {
  const currentPage = useLocation().pathname.split("/")[1].split(":")[0];

  const [isTransparent, setIsTransparent] = useState(
    !exeptionsOfTransparentEffect.includes(currentPage)
  );
  const [isStatic, setIsStatic] = useState(false);
  const [isOnLandingPage, setIsOnLandingPage] = useState(true);
  const prevScrollY = useRef(0);
  const [scrollY, setScrollY] = useState(0);
  const [goingUp, setGoingUp] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileMenuShowing, setIsMobileMenuShowing] = useState(false);

  const navbarRef = useRef(null);
  const homeLinkRef = useRef(null);
  const contactBtnRef = useRef(null);
  const mobileMenuBtn = useRef(null);
  const mobileMenuContainer = useRef(null);
  const mobileMenuLinks = useRef(null);
  const yLandingOffset = windowHeight * 0.17;

  const { state, dispatch } = useContext(LayoutContext);
  const showContactPopup = () => {
    dispatch({
      type: "TOGGLE_CONTACT_POPUP",
      payload: {
        isShowing: true,
      },
    });
  };

  let resizeWindow = () => {
    setWindowWidth(window.outerWidth);
    setWindowHeight(window.outerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (windowWidth < 700) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth, setWindowWidth, setIsMobile]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }
      setIsTransparent(currentScrollY === 0);

      setScrollY(currentScrollY);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  useEffect(() => {
    if (!dontShow.includes(currentPage)) {
      setIsStatic(exeptionsOfTransparentEffect.includes(currentPage));
      if (useStaticDesign.includes(currentPage) && !isMobile) {
        document.body.classList.add("hide-scroll");
        navbarRef.current.classList.add("navbar-static");
      } else {
        document.body.classList.remove("hide-scroll");
        navbarRef.current.classList.add("navbar-static");
      }
    }
    setIsOnLandingPage(currentPage === "");
  }, [currentPage, isMobile]);

  const toggleMobileMenu = () => {
    setIsMobileMenuShowing((state) => !state);
    if (isMobileMenuShowing) {
      hideMobileMenu();
    } else {
      showMobileMenu();
    }
  };

  const hideMobileMenu = () => {
    document.body.classList.remove("hide-scroll");
    mobileMenuBtn.current.classList.remove(
      "navbar-mobile-menu-btn-transformed"
    );

    mobileMenuLinks.current.classList.remove(
      "navbar-mobile-link-container-show"
    );
    setTimeout(() => {
      mobileMenuContainer.current.style.opacity = 0;
      mobileMenuContainer.current.style.display = "none";
      mobileMenuLinks.current.style.opacity = 0;
    }, 350);
  };

  const showMobileMenu = () => {
    document.body.classList.add("hide-scroll");
    mobileMenuBtn.current.classList.add("navbar-mobile-menu-btn-transformed");
    mobileMenuContainer.current.style.display = "block";
    mobileMenuContainer.current.style.opacity = 1;
    mobileMenuLinks.current.style.opacity = 1;
    setTimeout(() => {
      mobileMenuLinks.current.classList.add(
        "navbar-mobile-link-container-show"
      );
    }, 200);
  };

  useEffect(() => {
    if (state.isContactPopupShowing || isMobileMenuShowing) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [state.isContactPopupShowing, isMobileMenuShowing]);

  const standardNavbar = (
    <nav
      ref={navbarRef}
      className={`navbar`}
      style={{
        backgroundColor: isTransparent && !isStatic ? "transparent" : "#34373B",
      }}
    >
      <div className={`navbar-home-container`}>
        <Link to="/">
          <h1 ref={homeLinkRef} className={`navbar-home`}>
            Norbert Laszlo
          </h1>
        </Link>
      </div>
      <div className="navbar-link-container">
        <NavbarLinkStandard to="/projects" label="Projekt" />
        <NavbarLinkStandard to="/cv" label="CV" />
        <NavbarLinkStandard to="/timeline" label="Tidslinje" />
      </div>
      <div className="navbar-contact-btn-container">
        <ContactButton
          onClick={showContactPopup}
          btnRef={contactBtnRef}
          className={``}
        />
      </div>
    </nav>
  );

  const landingNavbar = (
    <Fragment>
      <Fade top when={scrollY < yLandingOffset}>
        <h1 ref={homeLinkRef} className={`navbar-title-landing`}>
          Norbert Laszlo
        </h1>
      </Fade>
      <nav
        ref={navbarRef}
        className={`navbar`}
        style={{
          backgroundColor: scrollY > yLandingOffset ? "#34373B" : "transparent",
        }}
      >
        <div className={`navbar-home-container`}>
          <Fade left collapse when={scrollY > yLandingOffset}>
            <Link to="/">
              <h1 ref={homeLinkRef} className={`navbar-home`}>
                Norbert Laszlo
              </h1>
            </Link>
          </Fade>
        </div>
        <div className="navbar-link-container">
          <NavbarLinkLanding
            to="/projects"
            label="Projekt"
            show={scrollY > yLandingOffset}
          />
          <NavbarLinkLanding
            to="/cv"
            label="CV"
            show={scrollY > yLandingOffset}
          />
          <NavbarLinkLanding
            to="/timeline"
            label="Tidslinje"
            show={scrollY > yLandingOffset}
          />
        </div>
        <div className={`navbar-contact-btn-container`}>
          <Fade right collapse when={scrollY > yLandingOffset}>
            <ContactButton
              onClick={showContactPopup}
              btnRef={contactBtnRef}
              className={``}
            />
          </Fade>
        </div>
      </nav>
    </Fragment>
  );

  const mobileNavbar = (
    <Fragment>
      <nav
        ref={navbarRef}
        className={`navbar`}
        style={{
          backgroundColor:
            isTransparent && !isStatic ? "transparent" : "#34373B",
        }}
      >
        <div className={`navbar-home-container navbar-home-container-mobile`}>
          <Link to="/">
            <h1 ref={homeLinkRef} className={`navbar-home navbar-home-mobile`}>
              Norbert Laszlo
            </h1>
          </Link>
        </div>
        <div
          className={`navbar-contact-btn-container navbar-contact-btn-container-mobile`}
        >
          <ContactButton
            onClick={showContactPopup}
            btnRef={contactBtnRef}
            className="navbar-contact-btn-mobile"
          />
        </div>
      </nav>
      <div ref={mobileMenuContainer} className="navmenu-mobile-container">
        <ul ref={mobileMenuLinks} className="navbar-mobile-link-container">
          <li>
            <NavbarLinkMobile
              to="/projects"
              label="Projekt"
              onClick={hideMobileMenu}
            />
          </li>
          <li>
            <NavbarLinkMobile to="/cv" label="CV" onClick={hideMobileMenu} />
          </li>
          <li>
            <NavbarLinkMobile
              to="/timeline"
              label="Tidslinje"
              onClick={hideMobileMenu}
            />
          </li>
          <li>
            <NavbarLinkMobile
              to="/projects"
              img={houseIcon}
              imgClass="navbar-home-icon"
              label="Home"
              onClick={hideMobileMenu}
            />
          </li>
        </ul>
      </div>
      <button
        ref={mobileMenuBtn}
        className="navbar-mobile-menu-btn"
        onClick={toggleMobileMenu}
        aria-label="Navigation menu"
      >
        <div aria-label="Navigation menu upper" />
        <div aria-label="Navigation menu middle" />
        <div aria-label="Navigation menu lower" />
      </button>
    </Fragment>
  );

  let navbar = landingNavbar;

  if (isMobile) {
    navbar = mobileNavbar;
  } else {
    if (isOnLandingPage) {
      navbar = landingNavbar;
    } else {
      navbar = standardNavbar;
    }
  }

  return <Fragment>{!dontShow.includes(currentPage) && navbar}</Fragment>;
};

export default Navbar;
