import React from "react";
import { useWindowDimensions } from "../inc/WindowDimensions";
import TimelineItem from "../components/ui/TimelineItem";
import tl_exp_list from "../data/tlExp";
import NameBounce from "../components/ui/NameBounce";
import { Helmet } from "react-helmet";

const Timeline = () => {
  const windowWidth = useWindowDimensions().width;

  return (
    <section className="page-section tl-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tidslinje</title>
        <link rel="canonical" href="http://norbertlaszlo.com/timeline" />
        <meta
          name="description"
          content="Tidslinje över min karriär. Data Science / Software Engineer / Developer"
        />
      </Helmet>
      <NameBounce text={"Norbert Laszlo"} />
      <div className="tl-header">
        <h2 className="current-year">2021</h2>
      </div>
      <div className="tl-main-container">
        {windowWidth > 600 && (
          <div className="tl-left-container">
            {tl_exp_list.map(
              (item, index) =>
                index % 2 === 0 && (
                  <TimelineItem
                    key={index + "left"}
                    leftSide={true}
                    year={item.year}
                    title={item.title}
                    text={item.text}
                  />
                )
            )}
          </div>
        )}
        <div className="tl-line">
          <div className="tl-line-crown"></div>
        </div>
        <div className="tl-right-container">
          {tl_exp_list.map(
            (item, index) =>
              (windowWidth <= 600 || index % 2 === 1) && (
                <TimelineItem
                  key={index + "right/main"}
                  leftSide={false}
                  year={item.year}
                  title={item.title}
                  text={item.text}
                />
              )
          )}
        </div>
      </div>
    </section>
  );
};

export default Timeline;
