import React from "react";
import DocView from "../components/layout/DocView";
import { useParams } from "react-router";

const DocumentView = () => {
  const { docName } = useParams();

  return (
    <section className="page-section doc-section">
      <DocView file={docName} />
    </section>
  );
};

export default DocumentView;
