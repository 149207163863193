import React from 'react';

import {getDoc} from '../../documents/';

const DocView = ({file}) => {
    return (
        <div>
            <iframe src={getDoc(file)} title="CV-Norbert_Laszlo" className='pdf-view'/>
        </div>
	);
};

export default DocView;
