import React from "react";
import Fade from "react-reveal/Fade";
import LazyImg from "../../components/ui/LazyImg";
import productiveLogo from "../../img/projects/pro-ductive/pro-ductive-logo.png";
import statisticsYearImg from "../../img/projects/pro-ductive/pro-ductive-img3.png";
import celebrationImg from "../../img/projects/pro-ductive/mainCelebration-border.png";
import comboImg from "../../img/projects/pro-ductive/pro-ductive-img1.png";
import appStoreScreenShot from "../../img/projects/pro-ductive/appStoreScreenShot-pro-ductive.png";
import appStore from "../../img/icons/appStore.png";
import googlePlay from "../../img/icons/googlePlay.png";
import { Helmet } from "react-helmet";

import projects_list from "../../data/projectsData";

const project = projects_list[0];
const ProDuctive = () => {
  return (
    <section className="project-page page-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pro-ductive</title>
        <link rel="canonical" href="http://norbertlaszlo.com/pro-ductive" />
        <meta
          name="description"
          content="Produktivitets app som hjälper dig att planera dina dagar, hålla koll dina vanor och rutiner. Simpel att använda och skapad för att maxa produktivitet."
        />
      </Helmet>
      <section className="header-section">
        <h1 className="project-title">
          {project.title}
          <LazyImg
            image={{
              src: productiveLogo,
              alt: "Pro-ductive app logo",
            }}
            className="pro-ductive-title-logo"
            effect="blur"
          />
        </h1>
        <p>{project.introText}</p>
      </section>
      <section className="split-section">
        <div className="main-content">
          <h2 className="project-subsection-title">Bakgrund</h2>
          <p>
            Utbudet av appar som ska hjälpa att maxa ens produktivitet, hålla
            koll på todo’s och bygga rutiner är många, men få lyckas faktisk
            göra det. De flesta är för komplicerade och kräver alldeles för
            många klick för att få simpla saker gjort. En produktivitet app som
            man kan integrera i sin vardag ska vara simpel att använda och
            matcha ens mentala bild av en planering. <br />
            <br /> Personligen så gillar jag att planera och strukturera mina
            dagar för att maximera min produktivitet och undvika att glömma bort
            saker. Dock så lyckades jag aldrig finna en app som hjälpte mig med
            dessa enkla saker och istället använde block eller lösa pappersblad.
            Därför valde jag att bygga en egen app som jag själv hade använt.
          </p>
        </div>
        <div className="sub-content">
          <Fade right>
            <LazyImg
              image={{
                src: comboImg,
                alt: "Pro-ductive app",
              }}
              className="pro-ductive-img1"
              effect="blur"
            />
          </Fade>
        </div>
      </section>
      <section className="split-section">
        <div className="sub-content">
          <Fade left>
            <LazyImg
              image={{
                src: celebrationImg,
                alt: "Pro-ductive app",
              }}
              className="pro-ductive-img2"
              effect="blur"
            />
          </Fade>
        </div>
        <div className="main-content">
          <h2 className="project-subsection-title">Syfte</h2>
          <p>
            Syftet med Pro-ductive är vara simpel och lätt att använda. Det går
            snabbt att planera sina dagar och enkelt att integrera användningen
            av appen i sin vardag. Appen hjälper också användaren till att följa
            upp hur ens vanor förändras över tid och få visuell feedback när man
            lyckas med sina dagliga mål. På så sätt hjälpa användaren att bygga
            nya rutiner och öka ens produktivitet.
          </p>
        </div>
      </section>
      <section className="split-section">
        <div className="main-content">
          <h3 className="project-subsection-title">Funktioner</h3>
          <ul>
            <li>Planera dagliga uppgifter (todo’s)</li>
            <li>Skapa och hantera vanor (habits)</li>
            <li>Följa statistik över ens vanor förändras </li>
            <li>Kalender</li>
            <li>Påminnelser</li>
            <li>och flera funktioner som kommer snart...</li>
          </ul>
        </div>
        <div className="sub-content">
          <Fade right>
            <LazyImg
              image={{
                src: statisticsYearImg,
                alt: "Pro-ductive app",
              }}
              className="pro-ductive-img3"
              effect="blur"
            />
          </Fade>
        </div>
      </section>
      <section className="split-section">
        <div className="sub-content">
          <Fade left>
            <LazyImg
              image={{
                src: appStoreScreenShot,
                alt: "Pro-ductive app",
              }}
              className="pro-ductive-img4 img-shadow"
              effect="blur"
            />
          </Fade>
        </div>
        <div className="main-content">
          <h2 className="project-subsection-title">Ladda ner</h2>
          <p>
            Pro-ductive finns att ladda ner både från{" "}
            <a
              target="blank"
              href="https://apps.apple.com/se/app/pro-ductive/id1583212095"
            >
              App Store
            </a>{" "}
            och
            <a
              target="blank"
              href="https://play.google.com/store/apps/details?id=com.raobert.pro_ductive&gl=SE"
            >
              {" "}
              Google Play
            </a>
            .
          </p>
          <Fade bottom>
            <div className="download-app-wrapper">
              <a
                target="blank"
                href="https://apps.apple.com/se/app/pro-ductive/id1583212095"
              >
                <LazyImg
                  image={{
                    src: appStore,
                    alt: "App Store",
                  }}
                  effect="blur"
                />
              </a>
              <a
                target="blank"
                href="https://play.google.com/store/apps/details?id=com.raobert.pro_ductive&gl=SE"
              >
                <LazyImg
                  image={{
                    src: googlePlay,
                    alt: "Google Play",
                  }}
                  effect="blur"
                />
              </a>
            </div>
          </Fade>
        </div>
      </section>
    </section>
  );
};

export default ProDuctive;
