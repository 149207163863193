import React, { createContext, useReducer } from "react";
import { LayoutReducer } from "./LayoutReducer";
import ContactPopup from "../components/layout/ContactPopup";
import Footer from "../components/layout/Footer";
import Navbar from "../components/layout/Navbar";

export const LayoutContext = createContext();

const layoutInitState = {
  isContactPopupShowing: false,
};

export const LayoutContextProvider = (props) => {
  const [state, dispatch] = useReducer(LayoutReducer, layoutInitState);

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      <ContactPopup isShowing={state.isContactPopupShowing} />
      <Navbar />
      {props.children}
      <Footer />
    </LayoutContext.Provider>
  );
};
