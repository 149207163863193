import React from "react";

const getWindowDimensions = ()  => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

const useWindowDimensions = ()  => {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  
    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }
//let windowWidth:number = useWindowDimensions().width
//let windowheight:number = useWindowDimensions().height

//export {windowWidth, windowheight};
export {useWindowDimensions};