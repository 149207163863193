class TlExp {
  constructor(year, title, text) {
    this.year = year;
    this.title = title;
    this.text = text;
  }
}

let tl_exp_list = [
  new TlExp(
    "2022 - 2023",
    "Software developer - Centiro",
    "Mjukvaruutvecklare med inslag av data science på Centiro. Jobbade deltid under skolåret och heltid på sommaren."
  ),
  new TlExp(
    "2021",
    "Påbörjad masters inom Data Science och AI",
    "Påbörjade masterprogram vid Chalmers tekniska högskola inom data science och AI."
  ),
  new TlExp(
    "2021",
    "Lanserade min app - Pro-ductive",
    "Va inte nöjd med utbudet av appar inom planering och produktivitet så bestämde mig för att skapa en app som heter Pro-ductive. Appen finns ute att hämtas på både App Store och Google Play."
  ),
  new TlExp(
    "2021",
    "Kandidatexamen inom Informationsteknik",
    "Kandidatexamen från Chalmers tekniska högskola inom informationsteknik / software engineering."
  ),
  new TlExp(
    "2020 - 2021",
    "Lärarassistent på Chalmers",
    "Handledare för studenter på Chalmers inom IT-relaterade ämnen som programmering och databaser. Arbetsuppgifter inkluderar: individuell handledning på av studenter på laborationspass, ansvar för övningspass, samt rättning av tentaminor och diverse inlämningar."
  ),
  new TlExp(
    "2018",
    "Påbörjad kandidat inom Informationsteknik",
    "Påbörjade mina studier på universitetsnivå vid Chalmers tekniska högskola inom informationsteknik / software engineering."
  ),
  new TlExp(
    "2018",
    "Blev antagen till Brewhouse inkubator",
    "Efter deltagandet i en innovationstävling så blev jag antaget till Brewhouse Inkubator i Göteborg som hjälpte mig att vidareutveckla mina entreprenöriella färdigheter. På Brewhouse deltog jag i ett program för startups med mitt företag under 6 månaders tid då jag erbjöds stöd och färdigutvecklade tjänsten WebbCV."
  ),
  new TlExp(
    "2018",
    "Startade mitt företag WebbCV",
    "Grundare och utvecklare av WebbCV vilket är en tjänst som hjälper arbetssökande med CV processen och presentera sig på ett modernt och mer interaktivt sätt i form av en egen hemsida."
  ),
  new TlExp(
    "2018",
    "Period av extra- och deltidsjobb",
    "Perioden mellan min hemkomst från Kanada och början av mina studier jobbade jag med mängder av olika saker. Foodtruck, festival personal, bartender och demonstration av olika produkter, bland annat premium elbilar."
  ),
  new TlExp(
    "2017 - 2018",
    "Anställning som kock",
    "Under min tid i Kanada tog jag en anställning på restaurangen Roundhouse som låg på bergstoppen av skidresorten. Började som köksbiträde med befordrades efter drygt en månad till kock."
  ),
  new TlExp(
    "2017 - 2018",
    "Skidsäsong och arbete i Whistler Kanada",
    "Flyttade till Kanada under vinterhalvåret av mitt sabbatsår för att åka skidor och säsongsjobba på den världsberömda skidresorten Whistler."
  ),
  new TlExp(
    "2017",
    "Anställning kundtjänst telenor",
    "Mitt första heltidsjobb efter gymnasiet. Kundtjänstmedarbetare och säljare på Telenor. Arbetsuppgifterna bestod i början av att lösa faktura och IT relaterade problem men övergick mer till sälj efter att jag blev befordrad till det interna säljteamet."
  ),
  new TlExp(
    "2017",
    "Gymnasieexamen",
    "Gymnasie examen i Teknik från Kitas gymnasium."
  ),
  new TlExp(
    "2015-2017",
    "Handbollstränare",
    "Efter att ha spelat handboll i många år var jag tvungen att lägga skorna på hyllan på grund av en skada, men hade för mycket kärlek för sporten för att lämna den helt och hållet. Så för att fortsätta vara involverad övergick jag till att coacha och vara ledare för mitt lag."
  ),
  new TlExp(
    "2015",
    "Extrajobb saluhall",
    "Extrajobb under gymnasie studietiden i Kvilles saluhall där jag sålde chark och ungerska delikatesser."
  ),
];

export default tl_exp_list;
