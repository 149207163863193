import React, { Fragment, useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LayoutContext } from "../../routing/LayoutContext";
const exceptionHide = ["document", "cv", "private", "geogame2"];

const Footer = () => {
  const currentPage = useLocation().pathname.split("/")[1].split(":")[0];
  const [isVisible, setIsVisible] = useState(
    !exceptionHide.includes(currentPage)
  );

  const { dispatch } = useContext(LayoutContext);
  const showContactPopup = () => {
    dispatch({
      type: "TOGGLE_CONTACT_POPUP",
      payload: {
        isShowing: true,
      },
    });
  };

  useEffect(() => {
    setIsVisible(!exceptionHide.includes(currentPage));
  }, [currentPage]);

  return (
    <Fragment>
      {isVisible && (
        <footer>
          <h2>
            <Link to={"/"} aria-label="Norbert Laszlo">
              Norbert Laszlo
            </Link>
          </h2>
          <div>
            <ul>
              <li>
                <Link to={"/cv"} aria-label="CV">
                  CV
                </Link>
              </li>
              <li>
                <Link to={"/timeline"} aria-label="Tidslinje">
                  Tidslinje
                </Link>
              </li>
              <li>
                <p onClick={() => showContactPopup()} aria-label="kontakt">
                  kontakt
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <Link
                  to={"/projects"}
                  aria-label="Projekt"
                  className="footer-category-title"
                >
                  Projekt
                </Link>
              </li>
              <li>
                <Link to={"/projects/webbcv"} aria-label="WebbCV">
                  <span>‣</span> WebbCV
                </Link>
              </li>
              <li>
                <Link to={"/projects/pro-ductive"} aria-label="Pro-ductive">
                  <span>‣</span> Pro-ductive
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      )}
    </Fragment>
  );
};

export default Footer;
