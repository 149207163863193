import React from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "../pages/NotFound";
import CV from "../pages/CV";
import Projects from "../pages/Projects";
import Timeline from "../pages/Timeline";
import Landing from "../pages/Landing";
import DocumentView from "../pages/DocumentView";

import WebbCV from "../pages/projects/WebbCV";
import ProDuctive from "../pages/projects/ProDuctive";
import GeoGame from "../pages/projects/GeoGame";
//import PrivateRoute from '../routing/PrivateRoute';
//import GeoGame from "../geoGame/GeoGame";
import { LayoutContextProvider } from "./LayoutContext";

const AppRoutes = () => {
  return (
    <>
      <LayoutContextProvider>
        <Routes>
          {/*<Route path="/private/geogame" element={<GeoGame />} />*/}
          <Route path="/" element={<Landing />} />
          <Route path="/cv" element={<CV />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/webbcv" element={<WebbCV />} />
          <Route path="/projects/pro-ductive" element={<ProDuctive />} />
          <Route path="/projects/geogame" element={<GeoGame />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/document:docName" element={<DocumentView />} />
          <Route element={<NotFound />} />
        </Routes>
      </LayoutContextProvider>
    </>
  );
};

export default AppRoutes;
