import React from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyVideo = (props) => {
  return (
    <LazyLoadComponent key={props.key} effect={props.effect}>
      {props.video.loop ? (
        <video className={props.className} loop autoPlay>
          <source src={props.video.src} type={props.video.type} />
        </video>
      ) : (
        <video className={props.className}>
          <source src={props.video.src} type={props.video.type} />
        </video>
      )}
    </LazyLoadComponent>
  );
};

export default LazyVideo;
