import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import LazyImg from "./LazyImg";
import LazyVideo from "./LazyVideo";
import mediaTypes from "../../models/mediaTypes";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ProjectItem = ({ route, title, introText, sliderImages }) => {
  return (
    <div className="project-item card-shadow">
      <Link
        className="project-item-link"
        aria-label={title}
        to={`/projects/${route}`}
      >
        <h2>{title}</h2>
      </Link>
      <p>
        {introText}..{" "}
        <Link
          className="project-item-inline-link"
          aria-label={"Läs mer om " + title}
          to={`/projects/${route}`}
        >
          Läs mer
        </Link>
      </p>
      <div className="project-carusel-center">
        <Slider
          {...settings}
          className="project-carusel-slider"
          autoplay={true}
          autoplaySpeed={5000}
        >
          {sliderImages &&
            sliderImages.map((item, index) => {
              let content = <h3>Media unavailable</h3>;
              if (item.type === mediaTypes.IMG) {
                content = (
                  <LazyImg
                    image={{
                      src: item.media,
                      alt: "",
                    }}
                    className="project-carusel-img"
                  />
                );
              } else if (item.type === mediaTypes.VIDEO) {
                content = (
                  <LazyVideo
                    video={{
                      src: item.media,
                      loop: true,
                      type: "video/mp4",
                    }}
                    className="project-carusel-video"
                  />
                );
              }
              return (
                <div key={index} className="project-carusel-content-wrapper">
                  {content}
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default ProjectItem;
