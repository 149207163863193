import React, { useState, useEffect, useRef } from "react";

import { useWindowDimensions } from "../../inc/WindowDimensions";

const NameBounce = ({ text, stepLength = 0.98 }) => {
  const winWidth = useWindowDimensions().width;
  const winHeight = useWindowDimensions().height;
  const [textPosition, setTextPosition] = useState({
    x: winHeight,
    y: 0,
    xDiff: stepLength,
    yDiff: stepLength,
  });

  // initial guess
  const [elementDimensions, setElementDimensions] = useState({
    width: 488,
    height: 34,
  });
  var textWidth = 300;
  useEffect(() => {
    setTimeout(() => {
      setTextPosition((textObj) => {
        if (
          (textObj.x + elementDimensions.width > winWidth &&
            textObj.xDiff > 0) ||
          (textObj.x < 0 && textObj.xDiff < 0)
        ) {
          return {
            ...textObj,
            xDiff: textObj.xDiff * -stepLength,
          };
        }
        if (
          (textObj.y + elementDimensions.height > winHeight &&
            textObj.yDiff > 0) ||
          (textObj.y < 0 && textObj.yDiff < 0)
        ) {
          return {
            ...textObj,
            yDiff: textObj.yDiff * -stepLength,
          };
        }

        return {
          ...textObj,
          x: textObj.x + textObj.xDiff,
          y: textObj.y + textObj.yDiff,
        };
      });
    }, 20);
  }, [
    textPosition,
    elementDimensions.height,
    elementDimensions.width,
    stepLength,
    winHeight,
    winWidth,
  ]);

  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setElementDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }, 300);
  }, [winWidth, winHeight]);

  return (
    <div className="namebounce-container">
      <p
        className="namebounce-text-lbl"
        style={{ left: textPosition.x, top: textPosition.y, with: textWidth }}
        ref={ref}
      >
        {text}
      </p>
    </div>
  );
};
export default NameBounce;
