import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImg = (props) => (
  <LazyLoadImage
    key={props.key}
    alt={props.image.alt}
    effect={props.effect}
    height={props.image.height}
    width={props.image.width}
    src={props.image.src}
    className={props.className}
    onClick={props.onClick}
  />
);

export default LazyImg;
