import Fade from "react-reveal/Fade";

const TimelineItem = ({ leftSide, year, title, text }) => {
  return (
    <Fade>
      <div className="tl-item-wrapper">
        <h3
          className={
            leftSide
              ? "tl-item-year tl-item-year-left"
              : "tl-item-year tl-item-year-right"
          }
        >
          {year}
        </h3>
        <div className="tl-item-container">
          {!leftSide && <div className="tl-item-line"></div>}
          <div
            className={
              leftSide
                ? "tl-item card-shadow tl-item-left"
                : "tl-item card-shadow tl-item-right"
            }
          >
            <h4 className="tl-item-title">{title}</h4>
            <p className="tl-item-text">{text}</p>
          </div>
          {leftSide && <div className="tl-item-line"></div>}
        </div>
      </div>
    </Fade>
  );
};

export default TimelineItem;
