import React from "react";
import DocView from "../components/layout/DocView";
import { Helmet } from "react-helmet";

const CV = () => {
  return (
    <section className="page-section cv-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CV / Resume</title>
        <link rel="canonical" href="http://norbertlaszlo.com/cv" />
        <meta
          name="description"
          content="Har mångsidiga kompetenser och uppskattar därför att vara delaktig i produktutvecklingens alla faser för att kunna bidra med så mycket som möjligt."
        />
      </Helmet>
      <DocView file={"cv"} />
    </section>
  );
};

export default CV;
