import React from "react";
import LazyImg from "../../components/ui/LazyImg";
import LazyVideo from "../../components/ui/LazyVideo";
import WebbCVLogo from "../../img/projects/webbcv/webbCv-img1.png";
import mobileAndDesktopVideo from "../../img/projects/webbcv/webbCv-video3.mp4";
import affisch from "../../img/projects/webbcv/affisch.jpg";
import instagramProfilePicture from "../../img/projects/webbcv/webbCv-img4.png";
import timeLineTemplate from "../../img/projects/webbcv/TL-wide-1.mp4";
import sfdVideo from "../../img/projects/webbcv/sfd-demo.mp4";
import Fade from "react-reveal/Fade";
import projects_list from "../../data/projectsData";
import { Helmet } from "react-helmet";

const project = projects_list[1];
const WebbCV = () => {
  return (
    <section className="project-page page-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>WebbCV</title>
        <link rel="canonical" href="http://norbertlaszlo.com/webbcv" />
        <meta
          name="description"
          content="CV-byggaren som hjälper dig att skapa snygga och interaktiva CV:n för att hjälpa dig sticka ut. Presentera dig på ett unik sätt genom en egen hemsida till CV."
        />
      </Helmet>
      <section className="header-section">
        <h1 className="project-title">
          <LazyImg
            image={{
              src: WebbCVLogo,
              alt: "Logo of WebbCV",
            }}
            className="webbcv-title-logo"
            effect="blur"
          />
        </h1>
        <p>{project.introText}</p>
      </section>
      <section className="split-section">
        <div className="main-content">
          <h2 className="project-subsection-title">Bakgrund</h2>
          <p>
            Att skriva CV:n kan vara knepigt. Vad man ska skriva och hur ska man
            få sig sticka ut från mängden är två svåra utmaningar, tyckte jag
            när jag skulle skriva mitt första CV:t iallafall. Så jag gjorde min
            research och skapade en egen hemsida till CV. Efter positiv feedback
            ville jag göra det möjligt även för andra att lätt kunna skapa en
            webbsida till CV och hjälpa guida dem genom processen av att skriva
            ett CV. Idén av CV-byggaren WebbCV föddes.
          </p>
        </div>
        <div className="sub-content">
          <Fade right>
            <LazyVideo
              video={{
                src: mobileAndDesktopVideo,
                loop: true,
                type: "video/mp4",
              }}
              className="webbcv-img1"
              effect="blur"
            />
          </Fade>
        </div>
      </section>
      <section className="split-section">
        <div className="sub-content">
          <Fade left>
            <LazyImg
              image={{
                src: affisch,
                alt: "",
              }}
              className="webbcv-img2 img-shadow"
              effect="blur"
            />
          </Fade>
        </div>
        <div className="main-content">
          <h2 className="project-subsection-title">Syfte</h2>
          <p>
            Anledningen till att jag skapade WebbCV var för att hjälpa andra som
            också tycker att det är svårt med att skriva CV:n och se fler lyckas
            att sticka ut från mängden för maximera sina chanser på
            arbetsmarknaden. WebbCV gör detta genom att sprida kunskap om vad
            man ska tänka på när man skriver och hur man ska presentera sig.
            CV-byggaren guidar användare genom alla stegen i processen av att
            skapa ett framgångsrikt digitalt CV.{" "}
          </p>
        </div>
      </section>
      <section className="split-section">
        <div className="main-content">
          <h2 className="project-subsection-title">Vad är ett webbCV?</h2>
          <p>
            Ett webbCV är ett digitalt interaktivt CV i formen av en egen
            hemsida. Denna kan se olika ut beroende på mall och hur man har
            stylat den. Mallarna är noga utvecklade för att presentera en på
            bästa möjliga sätt för en arbetsgivare. Fördelen med ett webbCV
            gentemot ett vanligt CV är att man inte är begränsad till formen av
            ett A4 papper. Man kan locka in läsaren genom att erbjuda en snabb
            översyn som lämnar intryck men även få med mer innehåll i det än vad
            ett vanligt CV tillåter då det är interaktivt.
          </p>
        </div>
        <div className="sub-content">
          <Fade right>
            <LazyVideo
              video={{ src: timeLineTemplate, loop: true, type: "video/mp4" }}
              className="webbcv-img3 img-shadow"
              effect="blur"
            />
          </Fade>
        </div>
      </section>
      <section className="split-section">
        <div className="sub-content">
          <Fade left>
            <LazyVideo
              video={{ src: sfdVideo, loop: true, type: "video/mp4" }}
              className="webbcv-img4"
              effect="blur"
            />
          </Fade>
        </div>
        <div className="main-content">
          <h3 className="project-subsection-title">Så här fungerar det</h3>
          <ol>
            <li>
              Välj den mall som passar dig bäst utifrån erfarenhet och karriär
            </li>
            <li>
              Skriv in din information genom att följa en enkel guide som
              hjälper dig i processen
            </li>
            <li>Anpassa och styla ditt webbCV efter din stil</li>
            <li>Dela ditt webbCV genom URL-länk, QR-kod eller PDF</li>
          </ol>
        </div>
      </section>
      <section className="split-section">
        <div className="main-content">
          <h2 className="project-subsection-title">Besök WebbCV</h2>
          <a
            href="https://www.webbcv.nu"
            target="blank"
            className="webbcv-link"
          >
            webbcv.nu
          </a>
        </div>
        <div className="sub-content">
          <Fade right>
            <LazyImg
              image={{
                src: instagramProfilePicture,
                alt: "",
              }}
              className="webbcv-img5"
              effect="blur"
            />
          </Fade>
        </div>
      </section>
    </section>
  );
};

export default WebbCV;
