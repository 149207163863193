//import webbCVImg1 from '../img/projects/webbCv-img1.png';
//import webbCVImg2 from '../img/projects/webbCv-img2.png';
import webbCVImg3 from "../img/projects/webbcv/webbCv-img3.png";
import webbCVImg4 from "../img/projects/webbcv/webbCv-img4.png";

import webbCVVideo1 from "../img/projects/webbcv/webbCv-video1.mp4";
import webbCVVideo2 from "../img/projects/webbcv/webbCv-video2.mp4";
import webbCVVideo3 from "../img/projects/webbcv/webbCv-video3.mp4";

import proDuctiveImg1 from "../img/projects/pro-ductive/pro-ductive-img1.png";
import proDuctiveImg2 from "../img/projects/pro-ductive/pro-ductive-img2.PNG";
import proDuctiveImg3 from "../img/projects/pro-ductive/pro-ductive-img3.png";
import proDuctiveImg4 from "../img/projects/pro-ductive/pro-ductive-img4.PNG";
import proDuctiveImg5 from "../img/projects/pro-ductive/pro-ductive-img5.png";
import proDuctiveImg6 from "../img/projects/pro-ductive/pro-ductive-img6.png";
import proDuctiveImg7 from "../img/projects/pro-ductive/pro-ductive-img7.png";

import geoGameWithTitle from "../img/projects/geoGame/geoGameWithTitle.jpg";

import mediaTypes from "../models/mediaTypes";

class Project {
  constructor(title, route, introText, img, slideShow) {
    this.title = title;
    this.route = route;
    this.introText = introText;
    this.img = img;
    this.slideShow = slideShow;
  }
}

const project1 = new Project(
  "Pro-ductive - Todo & Habit tracker",
  "pro-ductive",
  "Pro-ductive är en produktivitets app som hjälper dig att planera dina dagar, hålla koll dina vanor och rutiner, samt följa upp hur de ändras över tid. Simpel att använda och skapad för att maxa produktivitet. Finns att ladda ner på både App Store och Google Play.",
  proDuctiveImg1,
  [
    {
      media: proDuctiveImg7,
      type: mediaTypes.IMG,
    },
    {
      media: proDuctiveImg1,
      type: mediaTypes.IMG,
    },
    {
      media: proDuctiveImg2,
      type: mediaTypes.IMG,
    },
    {
      media: proDuctiveImg3,
      type: mediaTypes.IMG,
    },
    {
      media: proDuctiveImg4,
      type: mediaTypes.IMG,
    },
    {
      media: proDuctiveImg5,
      type: mediaTypes.IMG,
    },
    {
      media: proDuctiveImg6,
      type: mediaTypes.IMG,
    },
  ]
);

const project2 = new Project(
  "WebbCV - Det nya CV:t",
  "webbcv",
  "WebbCV är CV-byggaren som hjälper dig att skapa snygga och interaktiva CV:n för att hjälpa dig sticka ut och maxa dina chanser på arbetsmarknaden. Tjänsten hjälper användare genom hela CV-processen, innehåll, formulering och presentera sig på ett unik sätt genom en egen hemsida till CV.",
  webbCVImg3,
  [
    {
      media: webbCVImg4,
      type: mediaTypes.IMG,
    },
    {
      media: webbCVImg3,
      type: mediaTypes.IMG,
    },
    {
      media: webbCVVideo1,
      type: mediaTypes.VIDEO,
    },
    {
      media: webbCVVideo2,
      type: mediaTypes.VIDEO,
    },
    {
      media: webbCVVideo3,
      type: mediaTypes.VIDEO,
    },
  ]
);

const project3 = new Project(
  "GeoGame",
  "geogame",
  "GeoGame är ett online sällskapsspel med målet att gissa ursprunget eller platsen av det man ser på en bild varje runda. Varje runda ska spelarna på sina egna enheter gissa med hjälp av en karta ursprunget eller platsen man ser på bilden. Efter varje runda sammanfattas resultaten på en gemensam skärm, till exempel på en TV, och poäng delas ut efter hur nära man var svaret på kartan. Spelaren med minst antal poäng i slutet vinner!",
  geoGameWithTitle,
  [
    {
      media: geoGameWithTitle,
      type: mediaTypes.IMG,
    },
  ]
);

const projects_list = [project1, project2, project3];

export default projects_list;
